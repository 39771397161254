























































































import {ComponentProxy} from '@labor-digital/helferlein/lib/Entities/ComponentProxy';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {isPlainObject} from '@labor-digital/helferlein/lib/Types/isPlainObject';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {AppEventKeys} from '../../../../AppEventKeys';
import {AppStoreKeys} from '../../../../AppStoreKeys';
import {MainMenuFirstLevelLink} from '../../../../Interface/Links';
import LanguageSwitchMixin from '../../../../Mixin/LanguageSwitchMixin';
import {MenuService} from '../../../../Service/MenuService';
import Icon from '../../../Misc/Icon/Icon.vue';
import HomeLinkWrap from '../../../Misc/Link/HomeLinkWrap/HomeLinkWrap.vue';
import LinkWrap from '../../../Misc/Link/LinkWrap/LinkWrap.vue';
import MenuModuleRenderingMixin from '../Module/MenuModuleRenderingMixin';

export default {
    name: 'MainMenuDesktop',
    components: {
        Icon,
        HomeLinkWrap,
        LinkWrap
    },
    mixins: [MenuModuleRenderingMixin, LanguageSwitchMixin],
    props: {
        context: null as PageContext
    },
    data()
    {
        return {
            proxy: new ComponentProxy(this),
            openItem: null as MainMenuFirstLevelLink,
            leaveTimeout: 0,
            isBranchMenu: false
        };
    },
    computed: {
        items(): Array<PlainObject>
        {
            return MenuService.getMainMenuFirstLevel(true);
        },
        isLandingpage(): Boolean
        {
            return MenuService.isHomepageForLandingpages() || MenuService.isLandingpage;
        },
        branchItem(): PlainObject
        {
            return MenuService.getMainMenuBranchPage();
        },
        isDropdownOpen(): boolean
        {
            return this.openItem !== null;
        }
    },
    methods: {
        itemClasses(item)
        {
            return {
                'mainMenuDesktop__listItem--foreignLanguage': !this.isMainLanguage,
                'mainMenuDesktop__listItem--hover': isPlainObject(this.openItem) && item.id === this.openItem.id
            };
        },
        openDropdown(item: PlainObject, isBranchPage?: boolean)
        {
            this.$emit('dropdownOpen');
            this.openItem = item;
            this.moduleTargetLink = item;
            this.modulesForBranchMenu = isBranchPage === true;
            this.context.store.set(AppStoreKeys.SIDEBAR_MAIN_MENU_FIXED, true);
            this.context.store.set(AppStoreKeys.SIDEBAR_SHOW_OVERLAY, true);
        },
        closeDropdown()
        {
            this.$emit('dropdownClose');
            this.openItem = null;
            this.moduleTargetLink = null;
            this.modulesForBranchMenu = false;
            this.context.store.set(AppStoreKeys.SIDEBAR_SHOW_OVERLAY, false);
        },
        onItemClick(item: PlainObject, isBranchPage?: boolean)
        {
            if (!this.isMainLanguage) {
                return;
            }

            this.openDropdown(item, isBranchPage);
        }
    },
    created(): void
    {
        this.proxy.bind(this.context.eventEmitter, AppEventKeys.CLOSE_MAIN_MENU_DROPDOWN, () => {
            this.closeDropdown();
        });

        this.$watch(() => this.context.store.get(AppStoreKeys.SIDEBAR_SHOW_OVERLAY), (n, o) => {
            if (n === false && o !== false) {
                this.closeDropdown();
            }
        });
    }, beforeDestroy()
    {
        this.proxy.destroy();
    }
};
