var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mainMenuDesktop" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "mainMenuDesktop__row" }, [
        _c(
          "div",
          { staticClass: "mainMenuDesktop__list" },
          [
            _c(
              "home-link-wrap",
              {
                staticClass:
                  "mainMenuDesktop__listItem mainMenuDesktop__listItem--home",
                attrs: { context: _vm.context },
              },
              [_c("icon", { attrs: { icon: "house" } })],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.items, function (item) {
              return !_vm.isLandingpage
                ? _c(
                    "div",
                    {
                      key: item.uid,
                      staticClass: "mainMenuDesktop__listItem",
                      class: _vm.itemClasses(item),
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _c(
                        "link-wrap",
                        {
                          attrs: {
                            link: item.href,
                            "is-disabled": _vm.isMainLanguage,
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onItemClick(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.title) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e()
            }),
            _vm._v(" "),
            _vm._l(_vm.items, function (item) {
              return _vm.isLandingpage
                ? _c(
                    "div",
                    { key: item.uid, staticClass: "mainMenuDesktop__listItem" },
                    [
                      _c("link-wrap", { attrs: { link: item.slug } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.title) +
                            "\n                    "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e()
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "mainMenuDesktop__list mainMenuDesktop__list--branches",
          },
          [
            _c(
              "div",
              {
                staticClass: "mainMenuDesktop__listItem",
                class: _vm.itemClasses(_vm.branchItem),
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "link-wrap",
                  {
                    attrs: { link: _vm.branchItem.href, "is-disabled": "" },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onItemClick(_vm.branchItem, true)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.branchItem.title) +
                        "\n                    "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.isDropdownOpen
      ? _c("div", { staticClass: "mainMenuDesktop__dropdown" }, [
          _c("div", { staticClass: "mainMenuDesktop__dropdownInner" }, [
            _c(
              "div",
              {
                staticClass: "mainMenuDesktop__dropdownBody",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "mainMenuDesktop__dropdownUpper" },
                  _vm._l(_vm.modules, function (module) {
                    return _c(
                      module.component,
                      _vm._b(
                        { key: module.key, tag: "component" },
                        "component",
                        module.props,
                        false
                      )
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mainMenuDesktop__dropdownLower" },
                  _vm._l(_vm.formModules, function (module) {
                    return _c(
                      module.component,
                      _vm._b(
                        { key: module.key, tag: "component" },
                        "component",
                        module.props,
                        false
                      )
                    )
                  }),
                  1
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }